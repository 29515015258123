import 'antd/dist/antd.css';  // 或者 'antd/dist/antd.less'
import IndexRouter from './pages/router/IndexRouter';
import { BrowserRouter } from 'react-router-dom';
function App() {
  return (
      <> 
      <BrowserRouter>
        <IndexRouter></IndexRouter>
      </BrowserRouter>
      </>
  );
}

export default App;

