import {Routes, Route} from 'react-router-dom';
import Service from '@/pages/service';
import Custom from '@/pages/custom';
import Explore from '@/pages/explore';
import AboutUs from '@/pages/aboutUs';
import ContactUs from '@/pages/contactUs';
import Home from '@/pages/home/index';
export default function IndexRouter() {
  return (
    <Routes>
      {/* 首页 */}
      <Route path="/" element={<Home />} />
      {/* 服务页面 */}
      <Route path="/servicesAndProducts" element={<Service />} />
      {/* 案例页面 */}
      <Route path="/cases" element={<Custom />} />
      {/* 前沿探索 */}
      <Route path="/frontierExploration" element={<Explore />} />
      {/* 关于我们 */}
      <Route path="/aboutUs" element={<AboutUs />} />
      {/* 联系我们 */}
      <Route path="/contactUs" element={<ContactUs />} />
      {/* 重定向 */}
      {/* <Route path='/' render={()=>
            localStorage.getItem('token')?
            <NewsSandBox></NewsSandBox>:
            <Redirect to='/login'/>
        }/> */}
    </Routes>
  );
}
