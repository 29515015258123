import React from 'react';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { Col,Row ,Button ,Card,} from 'antd';
import {
    GithubFilled,
    LinkedinFilled,
    TwitterOutlined,
    BulbTwoTone
} from '@ant-design/icons';
import  './index.css'


const AboutUs: React.FC = () => {

return (
    <>
    <Header></Header>
        {/* 这是一家软件公司官网 */}
        <div className='AboutUs'>
        <Row justify="center" gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className='AboutUs-Text'>
                    <h1>这是一家软件公司官网</h1>
                    <p>
                        我们是一家专注于网页设计和开发的软件公司，致力于为客户提供高质量的解决方案。我们的团队由一群经验丰富、充满创造力的设计师和开发人员组成，我们深知用户体验的重要性，努力为客户打造令人满意的网站和应用程序。我们拥有丰富的项目经验，曾为众多知名企业提供服务，并获得了客户的高度评价。无论您是需要一个全新的网站还是对现有网站进行改进，我们都有能力帮助您实现目标。
                    </p>
                    <Button shape="round" type='primary' size='large'>了解更多</Button>
                    <Button shape="round" size='large' style={{marginLeft:'15px'}}>联系我们</Button>
                </div>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
               <div className='AboutUs-Box'>
                 <img
                    className='AboutUS-Img' 
                    src="https://img.jsdesign2.com/assets/img/6551bc3cd53a7ec77acde924.png" 
                    alt="" />
                 <img
                    className='AboutUS-Img' 
                    src="https://img.jsdesign2.com/assets/img/648aff61e6c676e4cae130bf.png" 
                    alt="" />
                 <img
                    className='AboutUS-Img' 
                    src="https://img.jsdesign2.com/assets/img/6486e258bfcc2e8f7620800b.png" 
                    alt="" />
               </div>
            </Col>
        </Row>
        </div>

        {/* 我们的团队 */}
        <div className='OurTeam'>
        <Row justify="center" gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className='OurTeam-Text'>
                    <h1>我们的团队</h1>
                    <p>
                        我们的团队由一群充满激情和创造力的人组成。我们拥有各种技术背景和专业知识，可以为客户提供全方位的技术支持和解决方案。我们注重团队合作，每个成员都充分发挥自己的优势，共同努力实现客户的目标。我们秉持着追求卓越和创新的精神，不断学习和进步，以提供最好的解决方案。
                    </p>
                </div>
            </Col>
        </Row>

        <Row  justify="space-around" align="middle" gutter={16} className='OurTeam-Row' >
            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>
            
            <Col xs={24} sm={24} md={24} lg={12} xl={6}>
                    <Card className='OurTeam-Card'>
                        <div className='OurTeam-Img'>
                            <img src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png" alt="" />
                        </div>
                        <h3>张三</h3>
                        <p>摄影师</p>
                        <span>
                        赵六是我们团队的项目经理，他有着丰富的项目管理经验和领导能力。他能够有效地组织团队工作，确保项目按时、高质量地完成。他善于沟通和协调，能够与    客户建立良好的合作关系。
                        </span>
                        <div>
                            <Button type="text">
                                <GithubFilled />
                            </Button>
                            <Button type="text">
                                <LinkedinFilled />
                            </Button>
                            <Button type="text">
                                <TwitterOutlined />
                            </Button>
                        </div>
                    </Card>
            </Col>
        </Row>

        <Row>
            <Col>
                <div className='OurTeam-Bottom'>
                    <h4>我们的团队</h4>
                    <p>我们在等你一起加入!</p>
                    <Button type='primary' shape="round" size='large'>
                        立即加入
                    </Button>
                </div>
            </Col>
        </Row>
        </div>

        {/* 我们的优势 */}
        <div className='OurAdvantages'>
        <Row justify="center" gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className='OurAdvantages-Text'>
                    <h1>我们的优势</h1>
                    <p>
                        我们有许多优势，使我们能够为客户提供卓越的服务。首先，我们拥有一支经验丰富的团队，他们在网页设计和开发方面具有出色的技能和知识。其次，我们注重用户体验，致力于为用户提供简洁、直观和易用的界面。此外，我们采用最新的技术和工具，确保我们的产品始终保持与时俱进。最后，我们注重与客户的沟通和合作，以确保我们理解客户的需求并提供最佳解决方案。
                    </p>
                </div>
            </Col>
        </Row>

        <Row justify="center" align="middle" gutter={60} className='OurAdvantages-Row' >
           <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <div className='OurAdvantages-Img'>
                <img src="https://img.jsdesign2.com/assets/img/6459bc0f2f5bf54faa7d927c.png" alt="" />
            </div>
           </Col>

           <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                <div className='OurAdvantages-Right-Text'>
                    <BulbTwoTone  className='OurAdvantages-Right-Icon' style={{fontSize:'24px'}}/>
                    <h3>客户规模覆盖完全满足不同企业需要</h3>
                    <p>以文档为中心的协作，可以通过知识库将知识进行连接，让知识能够以更为开放的形态在团队与团队</p>
                </div>
                <div className='OurAdvantages-Right-Text'>
                    <BulbTwoTone  className='OurAdvantages-Right-Icon' style={{fontSize:'24px'}}/>
                    <h3>客户规模覆盖完全满足不同企业需要</h3>
                    <p>以文档为中心的协作，可以通过知识库将知识进行连接，让知识能够以更为开放的形态在团队与团队</p>
                </div>
                <div className='OurAdvantages-Right-Text'>
                    <BulbTwoTone  className='OurAdvantages-Right-Icon' style={{fontSize:'24px'}}/>
                    <h3>客户规模覆盖完全满足不同企业需要</h3>
                    <p>以文档为中心的协作，可以通过知识库将知识进行连接，让知识能够以更为开放的形态在团队与团队</p>
                </div>
                <div className='OurAdvantages-Right-Text'>
                    <BulbTwoTone  className='OurAdvantages-Right-Icon' style={{fontSize:'24px'}}/>
                    <h3>客户规模覆盖完全满足不同企业需要</h3>
                    <p>以文档为中心的协作，可以通过知识库将知识进行连接，让知识能够以更为开放的形态在团队与团队</p>
                </div>
           </Col>

        </Row>

    </div>
    <Footer></Footer>
    </>   
)
}
export default AboutUs;