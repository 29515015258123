import React from 'react';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import {Col, Row, Button, Card} from 'antd';
import {RightOutlined} from '@ant-design/icons';
import './index.css';

const {Meta} = Card;

const Explore: React.FC = () => {
  const cardTitle = (
    <div className="IndustryLatest-Title">
      <span>社交类</span>
      <h5>编辑标题文案,标题将显示在此处</h5>
      <p>2000年后出生的一代人,他们在社交方面与前几代人有着很大的不同...</p>
    </div>
  );

  const cardInfo = (
    <div className="IndustryLatest-Info">
      <img
        src="https://img.jsdesign2.com/assets/img/643e2915ae639e792f955ea2.png"
        alt=""
      />
      <div className="IndustryLatest-Text">
        <h5>郑达北</h5>
        <p>2002年1月11日&nbsp;·&nbsp;5分钟前发布</p>
      </div>
    </div>
  );

  return (
    <>
      <Header></Header>
      {/* 产品计划 */}
      <div className="FrontierExploration">
        <Row>
          <Col span={24}>
            <div className="FrontierExplorationTitle">
              <h1>产品计划</h1>
              <p>
                在前端开发中，有许多最佳实践可以帮助开发者提高工作效率和代码质量。本文将介绍一些常用的前端开发最佳实践，包括代码组织、命名规范、性能优化等方面的内容。
              </p>
              <Button shape="round" size="large" style={{margin: '25px'}}>
                阅读全文
                <RightOutlined />
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="FrontierExplorationImg">
              <img
                src="https://img.jsdesign2.com/assets/img/6551b9e0723adfea2d02451a.png"
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>

      {/* 行业最新 */}
      <div className="IndustryLatest">
        <Row>
          <Col span={24}>
            <div className="IndustryLatestTitle">
              <h1>行业最新</h1>
              <p>此处为副标题此处为副标题此处为副标题。</p>
              <Button shape="round" style={{margin: '25px'}}>
                查看全部
              </Button>
              <Button shape="round" style={{margin: '25px'}}>
                第一类
              </Button>
              <Button shape="round" style={{margin: '25px'}}>
                第二类
              </Button>
              <Button shape="round" style={{margin: '25px'}}>
                第三类
              </Button>
              <Button shape="round" style={{margin: '25px'}}>
                第四类
              </Button>
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              bordered={false}
              style={{
                marginTop: '20px',
                borderRadius: '32px',
                backgroundColor: 'rgba(240,242,245,1)',
              }}
              className="IndustryLatest-Card"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/6551b9e0723adfea2d02451a.png"
                alt=""
              />
              <Meta title={cardTitle} description={cardInfo} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              bordered={false}
              style={{
                marginTop: '20px',
                borderRadius: '32px',
                backgroundColor: 'rgba(240,242,245,1)',
              }}
              className="IndustryLatest-Card"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/6551b9e0723adfea2d02451a.png"
                alt=""
              />
              <Meta title={cardTitle} description={cardInfo} />
            </Card>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              bordered={false}
              style={{
                marginTop: '20px',
                borderRadius: '32px',
                backgroundColor: 'rgba(240,242,245,1)',
              }}
              className="IndustryLatest-Card"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/6551b9e0723adfea2d02451a.png"
                alt=""
              />
              <Meta title={cardTitle} description={cardInfo} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Card
              hoverable
              bordered={false}
              style={{
                marginTop: '20px',
                borderRadius: '32px',
                backgroundColor: 'rgba(240,242,245,1)',
              }}
              className="IndustryLatest-Card"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/6551b9e0723adfea2d02451a.png"
                alt=""
              />
              <Meta title={cardTitle} description={cardInfo} />
            </Card>
          </Col>
        </Row>
      </div>
      <Footer></Footer>
    </>
  );
};
export default Explore;
