import React from 'react';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import { Col,Row ,Tabs,Button ,Card,Rate,Carousel,Divider} from 'antd';

import  './index.css'



const Custom: React.FC = () => {

    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '700px',
        color: '#fff',
        lineHeight: '700px',
        textAlign: 'center',
        background: '#364d79',
        borderRadius:'32px'
      };

    const onChangeTabs = (key: string) => {
        console.log(key);
      };

      const onChangeCarousel = (currentSlide: number) => {
        console.log(currentSlide);
      };

return (
    <>
    <Header></Header>
        {/* 软件公司客户案例 */}
        <div className='CustomerStories'>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} className='CustomerStories-Left'>
                    <div className='CustomerStories-Left-text'>
                        <h1>软件公司客户案例</h1>
                        <p>我们与全球各行各业的客户合作,提供创新的解决方案</p>
                        <Button type='primary' shape="round">金融</Button>
                        <Button type='primary' shape="round">电商</Button>
                        <Button type='primary' shape="round">科技</Button>
                        <Button type='primary' shape="round">医疗</Button>
                        <Button type='primary' shape="round">教育</Button>
                        <Button type='primary' shape="round">制造</Button>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12}>
                    <Row gutter={12}>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className='CustomerStories-Right-Text'>
                            <h3>项目规模</h3>
                                <p>覆盖全球多个国家和地区</p>
                        </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className='CustomerStories-Right-Text'>
                            <h3>技术实力</h3>
                                <p>拥有专业的技术团队和先进的技术设备</p>
                           </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                          <div className='CustomerStories-Right-Text'>
                            <h3>客户满意度</h3>
                                <p>95%以上的客户满意度</p>
                           </div>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className='CustomerStories-Right-Text'>
                            <h3>成功案例</h3>
                                <p>多个行业领先企业的选择</p>
                           </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
         </div>

        {/* 客户案例研究 */}
        <div className='CaseStudy'>
        <Row  gutter={50}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Carousel afterChange={onChangeCarousel}  autoplay effect="fade" style={{height:'100%'}}>
                    <div>
                      <h3 style={contentStyle}>1</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>2</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>3</h3>
                    </div>
                    <div>
                      <h3 style={contentStyle}>4</h3>
                    </div>
                </Carousel>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className='CaseStudyTitle'>
                    <h1>客户案例研究</h1>
                    <div className='CaseStudyTopTitle'>
                        <h2>¥价格:&nbsp;¥500起</h2>
                        <p>
                            <Divider type="vertical"className='CaseStudyIcon'/>
                            <Rate disabled defaultValue={5} />&nbsp;
                            <span>&nbsp;(5.0评分)&nbsp;10&nbsp;·&nbsp;条评价&nbsp;</span>
                        </p>
                    </div>
                    <p>我们为客户提供了多种解决方案,并取得了显著的业务成果</p>
                    <div className='CaseStudyCustomerIndustry'>
                        <h5>客户行业</h5>
                        <Button shape="round">金融</Button>
                        <Button shape="round">制造业</Button>
                        <Button shape="round">教育</Button>
                    </div>
                    <div className='CaseStudyCustomerIndustry'>
                        <h5>解决方案</h5>
                        <Button shape="round">电子商务</Button>
                        <Button shape="round">人工智能</Button>
                        <Button shape="round">云计算</Button>
                        <Button shape="round">大数据</Button>
                        <Button shape="round" style={{width:'350px'}} type="primary">查看更多案例</Button>
                    </div>
                    <div>
                        <Tabs
                            defaultActiveKey="1"
                            onChange={onChangeTabs}
                            items={[
                            {
                                label: `客户案例研究`,
                                key: '1',
                                children: `Content of Tab Pane 1`,
                            },
                            {
                                label: `成功故事`,
                                key: '2',
                                children: `Content of Tab Pane 2`,
                            },
                            {
                                label: `客户反馈`,
                                key: '3',
                                children: `Content of Tab Pane 3`,
                            },
                            ]}
                        />
                    </div>
                </div>
            </Col>
        </Row>
        </div>

        {/* 你们的反馈让我们做得更好 */}
        <div className='FeedBack'>
        <Row>
            <Col span={24}>
                <div className='FeedBackTitle'>
                    <h1>你们的反馈让我们做得更好</h1>
                    <p>这里可以写一些简单的感谢语</p>
                    <Button 
                        type='primary' 
                        size='large'
                        style={{borderRadius:'28px'}} >
                        填写反馈
                    </Button>
                </div>
            </Col>
        </Row>
        <Row justify="space-evenly" align='middle' gutter={15} style={{width:'100%',marginTop:'20px'}}>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8} >
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px' }}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300,borderRadius:'28px',marginTop:'20px' }}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>

                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                        <Card 
                        style={{ width: 300 ,borderRadius:'28px',marginTop:'20px'}}
                        className='FeedBack-Box'>
                            <Rate disabled defaultValue={3} style={{marginBottom:'15px'}}/>
                                <p>
                                “这里可以写一些看起来不错的评价内容，填充一些相对真实的数据，用作虚拟填充内容。可以从产品反馈中摘录。长度控制在四行内最佳。”
                                </p>
                            <div className='FeedBack-Info'>
                                <img src="https://img.jsdesign2.com/assets/img/6551b9e536b76e1b46cbf523.png" alt="" />
                                <div className='FeedBack-Text'>
                                    <h3>王成平</h3>
                                    <p>后端工程师</p>
                                </div>
                            </div>
                        </Card>
                    </Col>
        </Row>
        </div>
    <Footer></Footer>
    </>   
)
}
export default Custom;