import React from 'react'
import { Button, Row, Col, Card } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import Header from '@/components/header/Header'
import Footer from '@/components/footer/Footer'

import './index.css'

const Home: React.FC = () => {
  return (
    <>
      <Header></Header>
      {/* 专业的web界面设计服务 */}
      <div className="InterfaceDesign">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="Interface-Img"
          >
            <img
              src="https://img.jsdesign2.com/assets/img/6496c90abe67135be8c5a31c.png#6026dbb2bcec155bf60fd557616c303c"
              alt=""
            />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="Interface-Text"
          >
            <div>
              <h2>专业的web界面设计服务</h2>
              <p>
                我们提供高质量的web界面设计服务，帮助企业打造独特和吸引人的网站。无论是响应式设计、用户体验优化还是视觉设计，我们都能满足您的需求。
              </p>
              <Button shape="round">
                了解更多
                <RightOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      {/* 数据分析与挖掘 */}
      <div className="DataAnalysis">
        <Row>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={24}
            xl={12}
            className="DataAnalysis-Left"
          >
            <div className="DataAnalysis-Left-text">
              <h2>数据分析与挖掘</h2>
              <p>
                数据分析与挖掘是通过运用各种技术和方法，对大量数据进行挖掘和分析，从中找出有价值的信息和规律，以支持业务决策和优化业务流程。
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <Row gutter={12}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="DataAnalysis-Right-Text">
                  <h3>数据采集</h3>
                  <p>从各种数据源中获取数据，并进行清洗和整理。</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="DataAnalysis-Right-Text">
                  <h3>数据采集</h3>
                  <p>从各种数据源中获取数据，并进行清洗和整理。</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="DataAnalysis-Right-Text">
                  <h3>数据采集</h3>
                  <p>从各种数据源中获取数据，并进行清洗和整理。</p>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="DataAnalysis-Right-Text">
                  <h3>数据采集</h3>
                  <p>从各种数据源中获取数据，并进行清洗和整理。</p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* 最新资讯 */}
      <div className="Information">
        <Row>
          <Col span={24} className="Information-Title">
            <h1>最新资讯</h1>
            <p>
              近期公司发布了一系列最新的行业新闻，包括产品发布、合作动态等。
            </p>
          </Col>
          <Row justify="space-between" gutter={16} className="Information">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                className="Information-Card"
                hoverable
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/64916e386bbbc6ad6f707068.png#1e86ab1ab8050c505cdaa7e46b6d63b0"
                  />
                }
              >
                <h3>公司最新产品发布</h3>
                <p>我们最新推出了一款颠覆传统的产品，引起了广泛关注。</p>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                className="Information-Card"
                hoverable
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/6476def4681365f4842d37bc.png#145ddcb8c6f0224911d07a7c32e195b0"
                  />
                }
              >
                <h3>与知名企业合作</h3>
                <p>我们与一家全球知名企业达成了战略合作，共同推进行业创新。</p>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                hoverable
                className="Information-Card"
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/6551b9def99ac5ea8fc33e48.png#02d5a9b7fcb36cce471bd318e6fd58f0"
                  />
                }
              >
                <h3>公司成立十周年</h3>
                <p>
                  今年是我们公司成立的第十个年头，我们将继续为客户提供更好的服务。
                </p>
              </Card>
            </Col>
          </Row>
          <Row justify="space-between" gutter={16} className="Information">
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                hoverable
                className="Information-Card"
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/643f4d0146b7bed97bf6eff4.png#f5aea6df2516c312eb2d42e349a2b7f8"
                  />
                }
              >
                <h3>行业领先技术分享</h3>
                <p>
                  我们将在近期举办一次技术分享会，分享我们在行业领域的最新研究成果。
                </p>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                hoverable
                className="Information-Card"
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/648b1dd38a865ac971001831.png#32629578900aeee0ee70683ac64787b8"
                  />
                }
              >
                <h3>公司获得多项荣誉</h3>
                <p>
                  我们的产品在行业评选中获得了多项荣誉，展示了我们的技术实力。
                </p>
              </Card>
            </Col>

            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <Card
                hoverable
                className="Information-Card"
                cover={
                  <img
                    alt=""
                    src="https://img.jsdesign2.com/assets/img/64926a8eb1f7a99682027f52.png#959ee0b468548fcecccb030cf751512d"
                  />
                }
              >
                <h3>公司参加行业展会</h3>
                <p>
                  我们将参加一场国际知名的行业展会，展示我们的最新产品和技术。
                </p>
              </Card>
            </Col>
          </Row>
        </Row>
      </div>
      <Footer></Footer>
    </>
  )
}
export default Home
