import React, {useState} from 'react';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import {Row, Col, Form, Button, Input, Card, Select, message} from 'antd';
import {EnvironmentOutlined} from '@ant-design/icons';
import {getSubjectData, submitContactUsForm} from '@/services/api';
import './index.css';

const {TextArea} = Input;

const ContactUs: React.FC = () => {
  const [selectData, setSelectData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // 获取表单下拉框
  const getSelectData = async () => {
    try {
      const dataResponse = await getSubjectData();
      setSelectData(dataResponse.data);
    } catch (error) {
      // 处理错误
      console.error('Error fetching data:', error);
    }
  };

  // 提交表单
  const onFinish = async (values: any) => {
    setLoading(true);
    await submitContactUsForm(values);
    try {
      // 清空表单
      form.resetFields();
      setLoading(false);
      return message.success(`提交成功!`);
    } catch (error: any) {
      setLoading(false);
      return message.error(error.message || `提交失败!`);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Header></Header>
      {/* 联系我们 */}
      <div className="ContactUs">
        <Row justify="center" gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <div className="OurAdvantages-Text">
              <h1>Contact Us</h1>
              <p>
                We are an industry-leading company that values honesty,
                integrity, and efficiency. Building quality products and caring.
              </p>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Card className="ContactUs-Card">
              <Form
                form={form}
                layout="vertical"
                name="Contact-Us"
                wrapperCol={{span: 22}}
                onFinish={onFinish} // 提交表单且数据验证成功后回调事件
                onFinishFailed={onFinishFailed} // 提交表单且数据验证失败后回调事件
                autoComplete="off"
              >
                <Form.Item
                  label="Name"
                  name="userName"
                  rules={[{required: true, message: '请填写你的名字!'}]}
                >
                  <Input
                    allowClear
                    style={{
                      background: 'rgba(225,236,240,1)',
                      borderRadius: '10px',
                    }}
                    bordered={false}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="Email"
                  name="userEmail"
                  rules={[
                    {
                      type: 'email',
                      required: true,
                      message: '请正确填写你的邮箱!',
                    },
                  ]}
                >
                  <Input
                    allowClear
                    style={{
                      background: 'rgba(225,236,240,1)',
                      borderRadius: '10px',
                    }}
                    bordered={false}
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  label="Subject"
                  name="userSubject"
                  rules={[{required: true, message: '请填写你的问题!'}]}
                >
                  <Select
                    allowClear
                    style={{
                      background: 'rgba(225,236,240,1)',
                      borderRadius: '10px',
                    }}
                    bordered={false}
                    options={selectData}
                    onDropdownVisibleChange={getSelectData}
                  />
                </Form.Item>

                <Form.Item
                  label="message"
                  name="userMessage"
                  rules={[{required: true, message: '请填写你的反馈信息!'}]}
                >
                  <TextArea
                    allowClear
                    style={{
                      background: 'rgba(225,236,240,1)',
                      borderRadius: '10px',
                    }}
                    bordered={false}
                    showCount
                    maxLength={200}
                    placeholder="最多输入200字"
                  />
                </Form.Item>

                <Form.Item wrapperCol={{offset: 10, span: 16}}>
                  <Button type="primary" htmlType="submit">
                    提交
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
        <EnvironmentOutlined className="ContactUs-Icon" />
      </div>
      <Footer></Footer>
    </>
  );
};
export default ContactUs;
