import React from 'react';
import {Menu, Button, Dropdown, Row, Col} from 'antd';
import type {MenuProps} from 'antd';
import './Header.css';
import {DownOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';

const items: MenuProps['items'] = [
  {
    label: '首页',
    key: 'home',
  },
  {
    label: '服务与产品',
    key: 'servicesAndProducts',
    // children:[
    //   {
    //     label: '服务列表',
    //     key: 'serviceList',
    //   },
    //   {
    //     label: '产品列表',
    //     key: 'productList',
    //   },
    //   {
    //     label: '价格计划',
    //     key: 'pricePlan',
    //   },
    // ]
  },
  {
    label: '客户案例',
    key: 'cases',
    // children:[
    //   {
    //     label:'使用案例',
    //     key:'useCases',
    //   },
    //   {
    //     label:'成功故事',
    //     key:'successStory',
    //   },
    //   {
    //     label:'客户反馈',
    //     key:'customerFeedback',
    //   },
    //   {
    //     label:'问题反馈',
    //     key:'problemFeedback',
    //   },
    // ]
  },
  {
    label: '前沿探索',
    key: 'frontierExploration',
    // children:[
    //   {
    //     label:'产品计划',
    //     key:'productPlan',
    //   },
    //   {
    //     label:'行业最新',
    //     key:'industryLatest',
    //   },
    // ]
  },
  {
    label: '关于我们',
    key: 'aboutUs',
    // children:[
    //   {
    //     label:'公司历史',
    //     key:'companyHistory',
    //   },
    //   {
    //     label:'团队介绍',
    //     key:'teamIntroduction',
    //   },
    //   {
    //     label:'企业文化',
    //     key:'corporateCulture',
    //   },
    // ]
  },
  {
    label: '联系我们',
    key: 'contactUs',
    // children:[
    //   {
    //     label:'发起咨询',
    //     key:'initiateConsultation',
    //   },
    //   {
    //     label:'地图位置',
    //     key:'maplocation',
    //   },
    //   {
    //     label:'联系方式',
    //     key:'contactInformation',
    //   },
    // ]
  },
];
const NavBar: React.FC = () => {
  const navigate = useNavigate();

  const onClick: MenuProps['onClick'] = (e: any) => {
    if (e.key === 'home') {
      navigate(`/`);
    } else {
      navigate(`/${e?.key}`);
    }
  };

  return (
    <div className="NavBar">
      <Row justify="space-between" align="middle">
        <Col xs={8} sm={8} md={8} lg={6} xl={6} xxl={6}>
          <div className="NavBar-Logo">
            <p>惠古</p>
          </div>
        </Col>
        <Col xs={8} sm={8} md={8} lg={14} xl={14} xxl={14}>
          <Dropdown
            trigger={['click']}
            className="NavBar-Button"
            menu={{items, onClick}}
          >
            <Button>
              菜单
              <DownOutlined />
            </Button>
          </Dropdown>
          <Menu
            className="NavBar-Menu"
            style={{borderBottom: 'none'}}
            onClick={onClick}
            mode="horizontal"
            items={items}
          />
        </Col>
        <Col xs={2} sm={2} md={2} lg={2} xl={4} xxl={2}>
          <Button type="primary" shape="round">
            开始
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default NavBar;
