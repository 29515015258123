import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {ApiResponse} from './typings';

const api: AxiosInstance = axios.create({
  baseURL: '/api', // 代理请求会转发到 http://127.0.0.1:1010，而不是直接访问 /api
  // 可以在这里设置其他默认配置，如 headers、timeout 等
  timeout: 2 * 60 * 1000, // 请求超时时间
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json', // 设置请求的 Content-Type 为 JSON 格式
  },
});

// 定义表单下拉条接口
export const getSubjectData = async (): Promise<ApiResponse> => {
  const endpoint = '/official-website/get-consultation-types';

  try {
    const response: ApiResponse = await api.get(endpoint);
    return response;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// 定义表单接口
export const submitContactUsForm = async (data: any): Promise<ApiResponse> => {
  const endpoint = '/official-website/add'; // 接口2的相对 URL 地址
  try {
    const response: ApiResponse = await api.post(endpoint, data);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

// export async function submitContactUsForm(userData:contactUsAddData):Promise<ApiResponseContactUsAdd<any>>{
//     try {
//         const response = await axios.post(`${API_BASE_URL}${'/add'}`,userData);
//         console.log(userData,124124124);
//         return {
//             success:true,
//             message:'提交成功',
//             data:response.data
//         }
//       } catch (error) {
//         // 处理错误
//        return  {
//             success:false,
//             message:'提交失败',
//             data:null
//         }
//       }
// }

export default axios;
