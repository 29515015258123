import React from 'react';
import Header from '@/components/header/Header';
import Footer from '@/components/footer/Footer';
import {Col, Row, Tabs, Button, Card, Tag} from 'antd';
import {CheckSquareOutlined} from '@ant-design/icons';
import './index.css';

const Service: React.FC = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <>
      <Header></Header>
      {/* 我们的服务 */}
      <div className="OurServe">
        <Row>
          <Col span={24} className="OurServe-Top">
            <h1>我们的服务</h1>
            <p>我们提供多种优质产品，满足各种需求。</p>
          </Col>
          <Row justify="center" gutter={16} className="OurServe-Img">
            <Col
              xs={24}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              className="OurServe-Bottom-Img"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/644b2c56e5a51e19a41a8aae.png#8392e8a38bb5f9d7e6c35e068f8d798b"
                alt=""
              />
            </Col>

            <Col
              xs={24}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              className="OurServe-Bottom-Img"
            >
              <img
                src="https://img.js.design/assets/img/64e70b6e9db1a5bbe01a97bc.png"
                alt=""
              />
            </Col>

            <Col
              xs={24}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              className="OurServe-Bottom-Img"
            >
              <img
                src="https://img.jsdesign2.com/assets/img/644b2c56e5a51e19a41a8aae.png#8392e8a38bb5f9d7e6c35e068f8d798b"
                alt=""
              />
            </Col>
          </Row>
          <Row className="OurServe-Bottom-Row">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              className="OurServe-Bottom-Box"
            >
              <h3>拥抱未来 与时俱进</h3>
              <p>
                这是一个产品的简介。这个产品非常好用，可以帮助用户解决很多问题。
              </p>
              <Tabs
                style={{marginTop: '20px'}}
                size="small"
                defaultActiveKey="1"
                onChange={onChange}
                items={[
                  {
                    label: `功能介绍`,
                    key: '1',
                    children: `这个产品提供了很多实用的功能，可以帮助用户提高工作效率。我们提供24小时的技术支持，确保用户在使用过程中遇到问题能够得到及时解决。价格计划灵活多样，满足不同用户的需求。`,
                  },
                  {
                    label: `技术支持`,
                    key: '2',
                    children: `Content of Tab Pane 2`,
                  },
                  {
                    label: `价格计划`,
                    key: '3',
                    children: `Content of Tab Pane 3`,
                  },
                ]}
              />
            </Col>
          </Row>
        </Row>
      </div>

      {/* 我们的产品 */}
      <div className="OurProducts">
        <Row>
          <Row justify="center">
            <Col span={24} className="OurProducts-Top">
              <h1 style={{fontSize: '48px', fontWeight: '700'}}>我们的产品</h1>
              <p style={{fontSize: '18px', fontWeight: '400'}}>
                我们提供多种优质产品，满足各种需求。
              </p>
            </Col>
          </Row>
          <Row justify="space-around" className="OurProducts-Bottom">
            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className="OurProducts-Bottom-Col"
            >
              <img
                src="https://img.js.design/assets/smartFill/img422164da758808.jpg"
                alt=""
                style={{width: '100%'}}
              />
              <div className="OurProducts-Tag">
                <h5>产品1</h5>
                <p>产品1的描述</p>
                <Tag
                  color="#108ee9"
                  style={{borderRadius: '28px', marginTop: '30px'}}
                >
                  标签 1
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 2
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 3
                </Tag>
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className="OurProducts-Bottom-Col"
            >
              <img
                src="https://img.js.design/assets/smartFill/img436164da758808.jpg"
                alt=""
                style={{width: '100%'}}
              />
              <div className="OurProducts-Tag">
                <h5>产品2</h5>
                <p>产品2的描述</p>
                <Tag
                  color="#108ee9"
                  style={{borderRadius: '28px', marginTop: '30px'}}
                >
                  标签 1
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 2
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 3
                </Tag>
              </div>
            </Col>

            <Col
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              className="OurProducts-Bottom-Col"
            >
              <img
                src="https://img.js.design/assets/smartFill/img432164da758808.jpg"
                alt=""
                style={{width: '100%'}}
              />
              <div className="OurProducts-Tag">
                <h5>产品3</h5>
                <p>产品3的描述</p>
                <Tag
                  color="#108ee9"
                  style={{borderRadius: '28px', marginTop: '30px'}}
                >
                  标签 1
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 2
                </Tag>
                <Tag color="#108ee9" style={{borderRadius: '28px'}}>
                  标签 3
                </Tag>
              </div>
            </Col>
          </Row>
          <Row justify="center" style={{marginTop: '260px'}}>
            <Col span={24} style={{textAlign: 'center'}}>
              <Button style={{borderRadius: '40px'}}>查看更多</Button>
            </Col>
          </Row>
        </Row>
      </div>

      {/* 核心功能 */}
      <div className="CoreFunctions">
        <Row justify="center">
          <Col
            span={24}
            className="CoreFunctions-Top"
            style={{textAlign: 'center'}}
          >
            <h1 style={{fontSize: '48px', fontWeight: '700'}}>核心功能</h1>
            <p style={{fontSize: '18px', fontWeight: '400'}}>
              我们提供一流的核心功能,帮助您实现业务目标。
            </p>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="CoreFunctions-Col">
            <div className="CoreFunctions-Img">
              <img
                src="https://img.jsdesign2.com/assets/img/6457b3738569fc84612ae90f.png"
                alt=""
              />
            </div>
          </Col>
        </Row>

        <div className="CoreFunctions-Bottom">
          <Row justify="center" align="middle">
            <Col
              xs={18}
              sm={20}
              md={22}
              lg={22}
              xl={22}
              className="CoreFunctionsFirstCol"
            >
              <h4>智能推荐</h4>
              <p>通过智能算法，为用户提供个性化的推荐服务。</p>
            </Col>
            <Col xs={6} sm={4} md={2} lg={2} xl={2}>
              <Button style={{borderRadius: '40px'}} size="large">
                了解更多
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      {/* 价格计划 */}
      <div className="PricePlan">
        <Row justify="center">
          <Col
            span={24}
            className="PricePlan-Top"
            style={{textAlign: 'center'}}
          >
            <h1 style={{fontSize: '48px', fontWeight: '700'}}>价格计划</h1>
            <p style={{fontSize: '18px', fontWeight: '400'}}>
              我们提供灵活的价格计划，满足不同用户的需求。
            </p>
          </Col>
        </Row>

        <Row justify="space-around" gutter={15}>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{width: '100%'}}>
            <Card style={{borderRadius: '24px'}}>
              <div className="PricePlan-Card">
                <h6>基础版</h6>
                <h1>￥99元/月</h1>
                <p>
                  <span>或9.9/月</span>每年
                </p>
              </div>
              <div className="PricePlanFunction">
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能一
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能二
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能三
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能四
                </p>
              </div>
              <div className="PricePlanButton">
                <Button shape="round" size="large" block>
                  立即购买
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{width: '100%'}}>
            <Card style={{borderRadius: '24px'}}>
              <div className="PricePlan-Card">
                <h6>高级版</h6>
                <h1>￥199元/月</h1>
                <p>
                  <span>或19.9/月</span>每年
                </p>
              </div>
              <div className="PricePlanFunction">
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能一
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能二
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能三
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能四
                </p>
              </div>
              <div className="PricePlanButton">
                <Button shape="round" size="large" block>
                  立即购买
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={12} md={8} lg={8} xl={8} style={{width: '100%'}}>
            <Card style={{borderRadius: '24px'}}>
              <div className="PricePlan-Card">
                <h6>企业版</h6>
                <h1>￥399元/月</h1>
                <p>
                  <span>或39.9/月</span>每年
                </p>
              </div>
              <div className="PricePlanFunction">
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能一
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能二
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能三
                </p>
                <p>
                  <i>
                    <CheckSquareOutlined />
                  </i>
                  功能四
                </p>
              </div>
              <div className="PricePlanButton">
                <Button shape="round" size="large" block>
                  立即购买
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </div>

      <Footer></Footer>
    </>
  );
};
export default Service;
