import React from 'react';
import {Row, Col, Anchor} from 'antd';

import './Footer.css';

const {Link} = Anchor;

const PageFooter: React.FC = () => {
  return (
    <div className="Footer-Bottom">
      <Row className="Footer-Bottom-TopTitle">
        <Col xs={24} sm={24} md={15} lg={6} xl={12}>
          <h2>惠古</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In blandit
            tincidunt id turpis est platea sed.
          </p>
        </Col>

        <Col xs={24} sm={24} md={3} lg={6} xl={4} className="PageFooter-Col">
          <Link href="" title="首页" />
          <Link href="" title="变化" />
          <Link href="" title="服务" />
          <Link href="" title="价格" />
          <Link href="" title="团队" />
          <Link href="" title="团队" />
        </Col>

        <Col xs={24} sm={24} md={3} lg={6} xl={4}>
          <Link href="" title="首页" />
          <Link href="" title="变化" />
          <Link href="" title="服务" />
          <Link href="" title="价格" />
          <Link href="" title="团队" />
        </Col>

        <Col xs={24} sm={24} md={3} lg={6} xl={4}>
          <Link href="" title="首页" />
          <Link href="" title="变化" />
          <Link href="" title="服务" />
          <Link href="" title="价格" />
          <Link href="" title="团队" />
        </Col>
      </Row>
      <Row
        justify="center"
        align="middle"
        style={{background: 'rgba(255,255,255, 1)'}}
      >
        <Col>
          <div className="Footer-Bottom-Title">
            <p>
              © Copyright <span>苏州惠古科技有限公司</span>. All Rights Reserved
            </p>
            <p>
              <a href="https://beian.miit.gov.cn/#/Integrated/index">
                苏ICP备19073934号
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default PageFooter;
